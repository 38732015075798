export enum RouteId {
  Landing = 'landing',
  Home = 'home',
  Pledge = 'pledge',
  Consent = 'consent',
  Memorandum = 'Memorandum',
  Login = 'login',
  MobileCheck = 'mobile-check',
  FindInfo = 'find-info',
  SignUp = 'sign-up',
  MyInfo = 'my-info',
  Commute = 'commute',
  CommuteList = 'commute-list',
  CommuteCheckIn = 'commute-check-in',
  CommuteCheckOut = 'commute-check-out',
  Tbm = 'tbm',
  TbmList = 'tbm-list',
  TbmDetail = 'tbm-detail',
  SafetyTraining = 'safety-training',
  SafetyTrainingList = 'safety-training-list',
  SafetyTrainingDetail = 'safety-training-detail',
  Organization = 'organization',
  Evacuation = 'evacuation',
  EvacuationList = 'evacuation-list',
  EvacuationDetail = 'evacuation-detail',
  Board = 'board',
  BoardList = 'board-list',
  BoardDetail = 'board-detail',
  NotFound = 'not-found',
  Msds = 'msds',
  MsdsDetail = 'msds-detail',
  // 퍼시스를 위한 임시 redirect page RouteId
  FursysLanding = 'fursys-landing',
}
