import { Route } from 'react-router-dom';
import { RouteId } from 'utils';

export const msdsRoutes = (
  <Route id={RouteId.Msds} path=":projectId/msds">
    <Route
      id={RouteId.MsdsDetail}
      path=":msdsId"
      lazy={() => import('pages/msds/msds.detail.page')}
    />
  </Route>
);
