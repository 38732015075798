import { Route } from 'react-router-dom';
import { RouteId } from 'utils';

export const commuteRoutes = (
  <Route id={RouteId.Commute} path=":projectId/commute">
    <Route
      id={RouteId.CommuteList}
      index
      path="list"
      lazy={() => import('pages/commute/commute.list.page')}
    />
    <Route
      id={RouteId.CommuteCheckIn}
      index
      path="check-in"
      lazy={() => import('pages/commute/commute.check-in.page')}
    />
    <Route
      id={RouteId.CommuteCheckOut}
      index
      path="check-out"
      lazy={() => import('pages/commute/commute.check-out.page')}
    />
  </Route>
);
