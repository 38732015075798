import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { RouteId, createRoutePaths } from 'utils';

import { boardRoutes } from './board.routes';
import { commuteRoutes } from './commute.routes';
import { evacuationRoutes } from './evacuation.routes';
import { msdsRoutes } from './msds.routes';
import { safetyTrainingRoutes } from './safety-training.routes';
import { tbmRoutes } from './tbm.routes';

export const routeObjects = createRoutesFromElements(
  <Route path="/" lazy={() => import('pages/index.layout')}>
    <Route id={RouteId.Landing} index lazy={() => import('pages/index')} />
    {/**
     * 퍼시스를 위한 임시 redirect page
     */}
    <Route id={RouteId.FursysLanding} path="/fs" lazy={() => import('pages/fursys.landing.page')} />
    <Route id={RouteId.Login} path=":projectId/login" lazy={() => import('pages/login.page')} />
    <Route
      id={RouteId.MobileCheck}
      path=":projectId/mobile-check"
      lazy={() => import('pages/mobile-check.page')}
    />
    <Route
      id={RouteId.FindInfo}
      path=":projectId/find-info"
      lazy={() => import('pages/find-info.page')}
    />
    <Route
      id={RouteId.SignUp}
      path=":projectId/sign-up"
      lazy={() => import('pages/sign-up.page')}
    />

    <Route path="*" lazy={() => import('pages/logged-in.layout')}>
      <Route id={RouteId.Home} path=":projectId/home" lazy={() => import('pages/home.page')} />
      <Route
        id={RouteId.Pledge}
        path=":projectId/pledge"
        lazy={() => import('pages/pledge.page')}
      />
      <Route
        id={RouteId.Consent}
        path=":projectId/consent"
        lazy={() => import('pages/consent.page')}
      />
      <Route
        id={RouteId.Memorandum}
        path=":projectId/memorandum"
        lazy={() => import('pages/memorandum.page')}
      />
      <Route
        id={RouteId.MyInfo}
        path=":projectId/my-info"
        lazy={() => import('pages/my-info.page')}
      />

      <Route
        id={RouteId.Organization}
        path=":projectId/organization"
        lazy={() => import('pages/organization.page')}
      />
      {commuteRoutes}
      {evacuationRoutes}
      {tbmRoutes}
      {safetyTrainingRoutes}
      {boardRoutes}
      {msdsRoutes}
      <Route id={RouteId.NotFound} path="*" element={<>not found</>} />
    </Route>
  </Route>,
);

export const routePaths = createRoutePaths(routeObjects);
export const router = createBrowserRouter(routeObjects);
