import { ReactNode, useEffect } from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { AntdMobileProvider } from './AntdMobileProvider';

interface LocaleProviderProps {
  children: ReactNode;
}

export const LocaleProvider = (props: LocaleProviderProps) => {
  const { children } = props;
  const { i18n } = useTranslation();

  useEffect(() => {
    const localLanguage = localStorage.getItem('language');
    if (localLanguage) {
      i18n.changeLanguage(localLanguage);
      return;
    }

    const browserLanguage = navigator.language;
    if (!browserLanguage) return;

    if (browserLanguage.includes('en')) {
      i18n.changeLanguage('en');
    } else if (browserLanguage.includes('ko')) {
      i18n.changeLanguage('ko');
    } else if (browserLanguage.includes('vi')) {
      i18n.changeLanguage('vi');
    } else if (browserLanguage.includes('pl')) {
      i18n.changeLanguage('pl');
    } else if (browserLanguage.includes('id')) {
      i18n.changeLanguage('id');
    } else if (browserLanguage.includes('ja')) {
      i18n.changeLanguage('ja');
    } else if (browserLanguage.includes('th')) {
      i18n.changeLanguage('th');
    } else if (browserLanguage.includes('es')) {
      i18n.changeLanguage('es');
    } else if (browserLanguage.includes('zh-CN')) {
      i18n.changeLanguage('zh_cn');
    } else if (browserLanguage.includes('zh-TW')) {
      i18n.changeLanguage('zh_tw');
    }
  }, []);

  useEffect(() => {
    if (i18n.resolvedLanguage === 'zh_cn' || i18n.resolvedLanguage === 'zh-CN') {
      dayjs.locale('zh-cn');
      return;
    } else if (i18n.resolvedLanguage === 'zh_tw' || i18n.resolvedLanguage === 'zh-TW') {
      dayjs.locale('zh-tw');
      return;
    }
    dayjs.locale(i18n.resolvedLanguage || 'ko');
  }, [i18n.resolvedLanguage]);

  return <AntdMobileProvider>{children}</AntdMobileProvider>;
};
