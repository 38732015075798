import { Suspense } from 'react';

import { DialogProvider, ToastProvider } from '@aicon-dev/caas-ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import i18n from 'config/i18n.ts';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import App from './App.tsx';

// eslint-disable-next-line
import { LocaleProvider } from 'pages/provider/LocaleProvider.tsx';

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <Suspense>
      <QueryClientProvider client={queryClient}>
        <LocaleProvider>
          <DialogProvider>
            <ToastProvider>
              <App />
            </ToastProvider>
          </DialogProvider>
        </LocaleProvider>
      </QueryClientProvider>
    </Suspense>
  </I18nextProvider>,
  // </React.StrictMode>,
);
