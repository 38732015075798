import { Route } from 'react-router-dom';
import { RouteId } from 'utils';

export const evacuationRoutes = (
  <Route path=":projectId/evacuation">
    <Route
      id={RouteId.EvacuationList}
      index
      path="list"
      lazy={() => import('pages/evacuation/evacuation.list.page')}
    />
    <Route
      id={RouteId.EvacuationDetail}
      path=":evacuationId"
      lazy={() => import('pages/evacuation/evacuation.detail.page')}
    />
  </Route>
);
