import { RouteObject } from 'react-router-dom';

export interface RoutePathObject {
  id: string;
  path: string;
}

const flatRouteObjects = (routes: RouteObject[], parent?: RoutePathObject): RoutePathObject[] => {
  return routes.flatMap((route) => {
    const mergePath = (path?: string, parentPath?: string) => {
      if (!parentPath || parentPath === '/' || parentPath === '*') return path || '';
      if (!path) return parentPath;
      return `${parentPath}/${path}`;
    };
    const currentRoute = {
      id: route.id || '',
      path: mergePath(route.path, parent?.path),
    };

    if (route.children?.length) return flatRouteObjects(route.children, currentRoute);
    return currentRoute;
  });
};

/**
 * 라우트 데이터를 key-value 조합의 1차원 배열로 변환합니다.
 * @param routes
 * @returns
 */
export const createRoutePaths = (routes: RouteObject[]): RoutePathObject[] => {
  return flatRouteObjects(routes).map((e) => {
    return {
      ...e,
      path: `/${e.path}`,
    };
  });
};
