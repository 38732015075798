import axios from 'axios';

const baseURL = process.env.REACT_APP_API_SERVER || 'https://worker.api.caas.works/v1';

export const createAxios = () => {
  const token = window.localStorage.getItem('JWT_TOKEN');

  return axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${token || process.env.REACT_APP_DANGEROUS_HARD_CORDING_JWT}`,
      accept: 'application/json',
    },
  });
};
